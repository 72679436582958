/* eslint-disable  */
/**

 */

export default {
  artcUrl: "artc://mplay.gtrmt.cn/gt/room1_room1-RTS",
  flvUrl: "http://mplay.gtrmt.cn/gt/room1.flv",
  hlsUrl: "http://zjonline.gtrmt.cn/live/gtrmt.m3u8",
  rtmpUrl: "rtmp://mplay.gtrmt.cn/gt/room1",
};
