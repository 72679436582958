<template>
  <div style="" class="home">
    <div id="mse"></div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import Player from "xgplayer";
import HlsJsPlayer from "xgplayer-hls.js";
import liveConfig from "../api/liveConfig";

export default {
  name: "liveXgFlvCmp",
  data() {
    return {
      roomInfo: {
        updated: new Date(),
      },

      xgPlayer: null,
    };
  },

  components: {},
  props: {
    // 内容数据
    pullStreamUrl: {
      type: String,
      default: liveConfig.hlsUrl,
    },
  },
  methods: {
    initXg() {
      this.xgPlayer = new HlsJsPlayer({
        id: "mse",
        url: this.pullStreamUrl,
        // url:
        //   "https://play.magicmind.cn/gt/room1.flv?auth_key=1612755674-0-0-1d6d78bb7a1e627e2506a953b13c979b",
        isLive: true,
        playsinline: true,
        "x5-video-player-type": "h5",
        autoplay: true,
        width: "100%",
        fitVideoSize: "fixWidth",
        // height: 190,
        // width: window.innerWidth,
        // height: (window.innerWidth * 9) / 16,
        isLive: true,
        controls: true,
        preloadTime: 5,
        minCachedTime: 1,
        cors: true, // 请求是否跨域
        rotateFullscreen: false,
      });

      // player.getCssFullscreen();  // 全屏
      this.xgPlayer.once("ready", () => {
        console.log("ready");
      });
      this.xgPlayer.on("rotate", function (deg) {
        console.log(deg); // 旋转时会触发rotate事件，角度有四个值90，180，270，0
      });
    },
    resizeHandler: () => {
      document.getElementById("mse").style.height =
        (window.innerWidth * 16) / 9 + "px";
    },
  },

  mounted() {
    this.initXg();
    // window.onresize = () => this.resizeHandler();
  },
  created() {},
  beforeCreate() {},
  destroyed() {
    this.xgPlayer.destroy(); // 播放器销毁
  },
};
</script>
<style></style>
